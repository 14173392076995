import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"400\",\"500\",\"600\",\"800\"],\"display\":\"swap\",\"preload\":false,\"adjustFontFallback\":true,\"fallback\":[\"Sans\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/app/AdSense.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/app/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/dogs-blog/dogs-blog/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/app/YandexAdBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/app/YandexAdsScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BottomMobileAccountTabsMenu"] */ "/home/runner/work/dogs-blog/dogs-blog/src/components/account/BottomMobileAccountTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/home/runner/work/dogs-blog/dogs-blog/src/components/web-vital.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/features/menu/ui/DesktopTabsMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/features/menu/ui/DesktopUserMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/features/menu/ui/MobileBurgerMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/features/show-cookies-concent/ui/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/features/show-cookies-concent/ui/CookieConsentButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/buttons/BackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/buttons/ShareButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/call-to-number/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/HiddenLink.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/icons/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/theme/index.tsx");
