'use client';

import Script from 'next/script';
import { useTheme } from 'next-themes';

const settings = [
  {
    initId: 'fullscreen-touch',
    blockId: 'R-A-11959228-1',
    type: 'fullscreen',
    platform: 'touch'
  },
  {
    initId: 'fullscreen-desktop',
    blockId: 'R-A-11959228-2',
    type: 'fullscreen',
    platform: 'desktop'
  },
  {
    initId: 'topAd',
    blockId: 'R-A-11959228-3',
    type: 'topAd'
  },
  {
    initId: 'floorAd',
    blockId: 'R-A-11959228-4',
    type: 'floorAd',
    platform: 'desktop'
  }
];

const YandexFullscreenAdBlock = () => {
  const { resolvedTheme } = useTheme();

  return (
    <Script id={`yandex-ad-block`} strategy="afterInteractive">
      {`
        window.yaContextCb = window.yaContextCb || [];
        ${settings
          .map(
            ({ blockId, type, platform }) => `
            window.yaContextCb.push(() => {
              Ya.Context.AdvManager.render({
                "blockId": "${blockId}",
                "type": "${type}"${platform ? `,"platform": "${platform}"` : ''}
                ,"darkTheme": ${resolvedTheme === 'dark' ? true : false}
              });
            });`
          )
          .join('\n')}
      `}
    </Script>
  );
};

export default YandexFullscreenAdBlock;
