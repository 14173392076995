'use client';

import Script from 'next/script';

const YandexAdsScript = () => {
  return (
    <>
      <Script id="yandex-ads-init" strategy="beforeInteractive">
        {`window.yaContextCb = window.yaContextCb || [];`}
      </Script>
      <Script src="https://yandex.ru/ads/system/context.js" strategy="beforeInteractive" />
    </>
  );
};

export default YandexAdsScript;
